import React, { FC } from 'react';
import Form from 'src/components/Form';
import { Theme } from 'src/theme';
import { Attachments, Email as Model } from 'src/types/email';
import { downloadDocUtil } from 'src/utils/downloadDocument';
import * as Yup from 'yup';

import {
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIcon,
  Tooltip,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

interface Props {
  className?: string;
  entity: Model;
  action: (values: any) => Promise<void | string | object>;
  labels: {
    cardTitle: string;
    submitLabel: string;
    messages: {
      success: string;
      fail: string;
    };
  };
  cardHeaderAction?: any;
  token?: string;
  attachments?: Attachments[];
  deleteFile?: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  hover: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  }
}));

const EntityEditForm: FC<Props> = ({ entity, token, attachments, deleteFile, action, labels, cardHeaderAction }) => {
  const classes = useStyles();

  const renderAttachment = () => {
    if (entity?.attachment)
      return (
        <Typography color="primary">
          Atasament
          <br />
          <Link
            component="a"
            color="textSecondary"
            underline="always"
            href={`/view/${entity.attachment}`}
            target="_blank"
          >
            {entity.title}.pdf
          </Link>
        </Typography>
      );
    return null;
  };

  const onClick = (file: Attachments) => () => {
    downloadDocUtil(file.id, {
      title: file.originalName,
      docType: 'FILE',
      token
    });
  };

  const handleDelete = (index: number) => () => {
    deleteFile(index);
  };

  const renderAttachments = () => (
    <>
      <Typography color="primary">Atasamente</Typography>
      <List>
        {attachments.map((file, i) => (
          <ListItem divider={i < attachments.length - 1} key={file.id}>
            <ListItemIcon>
              <FileCopyIcon />
            </ListItemIcon>
            <ListItemText className={classes.hover} primary={file.originalName} onClick={onClick(file)} />
            <Tooltip title="Stergere">
              <IconButton edge="end" onClick={handleDelete(i)}>
                <SvgIcon color="error">
                  <DeleteIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </>
  );

  return (
    <Form
      fields={[
        {
          label: 'De la',
          name: 'from',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.from,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'Catre',
          name: 'to',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.to,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'CC',
          name: 'cc',
          initialValue: entity?.cc,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'Subiect',
          name: 'subject',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.subject,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'Titlu',
          name: 'title',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.title,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'Continut',
          name: 'content',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.content,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 },
          props: {
            multiline: true
          }
        },
        {
          label: 'Subsol',
          name: 'footer',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.footer,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 },
          props: {
            multiline: true
          }
        }
      ]}
      messages={labels.messages}
      action={action}
      submitLabel={labels.submitLabel}
      cardTitle={labels.cardTitle}
      error={!entity}
      cardHeaderAction={cardHeaderAction}
      spacing={3}
      footer={attachments?.length ? renderAttachments() : renderAttachment()}
    />
  );
};

export default EntityEditForm;
