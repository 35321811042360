/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import { useSnackbar } from 'notistack';
import React, { FC, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import useAuth from 'src/hooks/useAuth';

import Skeleton from '@material-ui/lab/Skeleton';
import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import { Editor } from '@tinymce/tinymce-react';

interface Props {
  content: string;
  attachmentApi: (
    params: { [paramName: string]: string | number | boolean },
    data: FormData,
    query: { [paramName: string]: string | number | boolean | object }
  ) => Promise<FormData>;
  ref: any;
  disabled?: boolean;
  height?: number | string;
  pdfLink?: string;
  viewMode?: boolean;
  loading?: boolean;
  isExcel?: boolean;
  refetchPdfData?: boolean;
}

const UPLOAD_IMAGE_FAIL = 'Problema incarcare poza';

const TextEditor: FC<Props> = forwardRef(
  ({ content, attachmentApi, disabled, height, viewMode, pdfLink, loading, isExcel, refetchPdfData }, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const editorRef = useRef(null);
    const { getAccessToken } = useAuth();
    const spreadsheetRef = React.useRef(null);
    const spreadsheetCreated = useRef(false);
    const [pdfLoading, setPdfLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [pdfData, setPdfData] = useState<string>(null);

    const getData = async () => {
      try {
        const token = getAccessToken();
        const response = await fetch(pdfLink, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        setPdfData(URL.createObjectURL(blob));
      } catch (err: any) {
        console.error('Failed to fetch PDF', err);
        setError(true);
      }
    };

    useEffect(() => {
      if (loading) setPdfLoading(true);
      if (!loading && viewMode && pdfLink && pdfLink.indexOf('undefined') < 0 && !pdfData) getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, pdfLink, pdfData]);

    useEffect(() => {
      if (refetchPdfData) {
        setPdfLoading(true);
        setPdfData(null);
        getData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchPdfData]);

    useImperativeHandle(ref, () => ({
      getContent: (params?: any) => {
        return editorRef.current?.getContent(params);
      },
      getExcelContent: async () => {
        const spreadsheet = spreadsheetRef.current;
        const data = await spreadsheet.saveAsJson();
        console.log('Excel data -->', data);
        const { jsonObject } = data;
        return JSON.stringify(jsonObject);
      }
    }));

    const saveExtraData = (t, ce) => {
      const top = (document.getElementsByClassName('e-active-cell')?.[0] as HTMLElement)?.style?.top;
      const left = (document.getElementsByClassName('e-active-cell')?.[0] as HTMLElement)?.style?.left;
      const { address, value } = t;
      if (top && left && address && value && value.indexOf('.signature') > -1) {
        ce.updateCell({ coordinates: `${top.slice(0, -2)};${left.slice(0, -2)}` }, address);
      }
    };

    const changeExcelData = useCallback(() => {
      if (spreadsheetRef.current && spreadsheetCreated.current) {
        if (content === '{}') {
          spreadsheetRef.current.refresh(true);
        } else {
          const jsonData = JSON.parse(content);
          spreadsheetRef.current.openFromJson({ file: jsonData });
        }
      }
    }, [content]);

    useEffect(() => {
      if (spreadsheetRef.current) {
        spreadsheetRef.current.cellEdited = (t) => saveExtraData(t, spreadsheetRef.current);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spreadsheetRef.current]);

    useEffect(() => {
      if (isExcel) {
        setTimeout(() => changeExcelData(), 100);
      }
    }, [content, isExcel, changeExcelData]);

    const created = () => {
      spreadsheetCreated.current = true;
      if (content && content !== '{}') {
        try {
          changeExcelData();
        } catch (e) {
          console.error('Could not load Excel Json', e);
        }
      }
    };

    const handleUploadClick = () => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
    };

    const uploadImageCallBack = async (blobInfo: any, success: any, failure: any) => {
      const data = new FormData();
      data.append('attachment', blobInfo.blob(), blobInfo.filename());
      try {
        const response: any = await attachmentApi(null, data, { isPublic: true });
        success(response.uri);
      } catch (e) {
        enqueueSnackbar(`${UPLOAD_IMAGE_FAIL}: ${e.message}`, {
          variant: 'error'
        });
        failure(e.message);
      }
    };

    const renderLoading = () => (
      <div style={{ height: '100%' }}>
        {[...Array(30)].map((i, k) => (
          <Skeleton
            key={i}
            style={{ marginLeft: k % 5 === 0 ? 70 : 0, marginRight: k % 3 === 0 ? 70 : 0 }}
            variant="text"
            height={30}
          />
        ))}
      </div>
    );

    const renderError = () => (
      <div
        style={{
          height: '50%',
          color: 'red',
          fontSize: 32,
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        Oups, a aparut o problema, va rugam reveniti!
      </div>
    );

    return error ? (
      renderError()
    ) : loading ? (
      renderLoading()
    ) : viewMode ? (
      <div style={{ height: '100%' }}>
        {pdfData && (
          <object
            data={pdfData}
            type="application/pdf"
            width="100%"
            onLoad={() => setPdfLoading(false)}
            height={pdfLoading ? 0 : height || 800}
          />
        )}
        {pdfLoading && renderLoading()}
      </div>
    ) : isExcel ? (
      <SpreadsheetComponent
        ref={spreadsheetRef}
        created={created}
        allowSave
        saveUrl="https://texo-ss.bwise.dev/api/spreadsheet/save"
        allowOpen
        openUrl="https://texo-ss.bwise.dev/api/spreadsheet/open"
        height={height || 700}
      />
    ) : (
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        apiKey="gpl"
        initialValue={content}
        disabled={disabled}
        init={{
          height: height || 700,
          toolbar_mode: 'floating',
          content_css: 'document',
          plugins:
            'advlist anchor autolink charmap code codesample directionality fullpage help hr image imagetools insertdatetime link lists media nonbreaking pagebreak preview print searchreplace table template textpattern toc visualblocks visualchars wordcount',
          toolbar:
            'undo redo | formatselect | bold italic strikethrough forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat',
          file_picker_types: 'image',
          file_picker_callback: handleUploadClick,
          images_upload_handler: uploadImageCallBack,
          pagebreak_separator: '<div style="page-break-before: always !important;">&nbsp;</div>'
        }}
      />
    );
  }
);

export default TextEditor;
