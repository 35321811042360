import { useSnackbar } from 'notistack';
import React, { FC } from 'react';
import { Copy as CopyIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Theme } from 'src/theme';

import { Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

interface ShareModalProps {
  visible: boolean;
  pin: string;
  link: string;
  cancel: (event: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cancel: {
    color: theme.palette.primary.main
  },
  action: {
    color: theme.palette.success.main
  }
}));

const ShareModal: FC<ShareModalProps> = ({ visible, pin, link, cancel }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const copyUrl = () => {
    if (link) {
      window?.navigator?.clipboard?.writeText(`Puteti accesa documentul cu PIN-ul ${pin} aici: ${link}`);
      enqueueSnackbar('Link copiat in clipboard', { variant: 'success' });
    } else {
      enqueueSnackbar('Link-ul nu a putut fi copiat', { variant: 'error' });
    }
  };

  const close = (event) => {
    cancel(event);
  };

  return (
    <Dialog open={visible} onClose={close} PaperProps={{ style: { overflow: 'hidden' } }} fullWidth disableEnforceFocus>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ margin: 20 }}>
            <Typography variant="h3">{`PIN: ${pin}`}</Typography>
            <TextField
              disabled
              label="Link"
              name="link"
              onChange={null}
              value={link}
              variant="outlined"
              style={{ marginTop: 20, width: '530px' }}
            />
          </Box>
        </DialogContent>
      </PerfectScrollbar>
      <DialogActions>
        <Button onClick={close} startIcon={<CloseIcon />} className={classes.cancel}>
          Inchide
        </Button>
        <Button onClick={copyUrl} startIcon={<CopyIcon />} className={classes.action}>
          Copiaza link
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareModal;
